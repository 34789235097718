import Axios from "axios"

import {
  SET_STEP,
  SET_DESTINATION,
  SET_CAMPAIGN,
  CHECK_PHONE,
  SET_TOKEN,
  GET_CHURCH_LIST,
  SET_CHURCH_CAMPAIGN,
  UPDATE_COORDINATES,
  SET_CREDIT_CARD,
  SET_ADDRESS,
  LOGOUT,
  SET_CONFIRMATION,
  SET_USER_DATA,
  GET_ALL_DONATIONS,
  GET_RECURRENT_DONATIONS,
  SET_MAIN_CAMPAIGN,
  SET_DONATION_STATUS,
  SET_FORM_OF_PAYMENT,
  SET_PIX_DATA,
} from "./actions.js"

// ============ Initial State ============
const initialState = {
  donationStep: null,
  donationCompleted: false,
  donationDestination: {},
  campaignDestination: {},
  confirmationData: {
    aut: "",
    campaignName: "",
    institutionName: "",
    paymentType: "",
    value: "",
    paymentDate: "",
    currency: "",
  },

  phoneRecognized: false,
  user: {
    token: null,
    id: null,
    name: null,
    phonenumber: null,
    data: {},
    donations: {},
  },
  creditCard: {},
  address: {},
  coords: {},

  churchList: {},

  paymentType: "credit",

  defaultCampaigns: {},

  churchSelected: {},
  campaignSelected: {},
  mainCampaign: {},
  pixData: {},
}

// ============ Action ============

// --> Donation
export const chooseDestination = donationDestination => ({
  type: SET_DESTINATION,
  donationDestination,
})
export const chooseCampaign = campaignDestination => ({
  type: SET_CAMPAIGN,
  campaignDestination,
})
export const setStep = donationStep => ({ type: SET_STEP, donationStep })
export const setConfirmed = confirmationData => ({
  type: SET_CONFIRMATION,
  confirmationData,
})

export const setPixDataUser = pixData => ({
  type: SET_PIX_DATA,
  pixData,
})

export const setAllDonations = donations => ({
  type: GET_ALL_DONATIONS,
  donations,
})
export const getAllDonations = () => (dispatch, store) =>
  new Promise((resolve, reject) => {
    const { token } = store().app.user
    Axios.get(`${process.env.GATSBY_API_URL}/donations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp.data)
        dispatch(setAllDonations(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })

export const getDonationsValues = id => {
  return Axios.get(
    `${process.env.GATSBY_API_URL}/institutions/${id}/donation-values`
  )
}

export const setRecurrentDonations = donations => ({
  type: GET_RECURRENT_DONATIONS,
  donations,
})
export const getRecurrentDonations = () => (dispatch, store) =>
  new Promise((resolve, reject) => {
    const { token } = store().app.user
    Axios.get(`${process.env.GATSBY_API_URL}/maintenances`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp.data)
        dispatch(setAllDonations(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })

// --> Auth
export const checkPhone = phoneRecognized => ({
  type: CHECK_PHONE,
  phoneRecognized,
})
export const fetchCheckPhone = phoneNumber => dispatch =>
  new Promise((resolve, reject) => {
    Axios.post(
      `${process.env.GATSBY_API_URL}/account/verify-phonenumber-exists`,
      {
        phoneNumber,
      }
    )
      .then(resp => {
        dispatch(checkPhone(true))
        resolve(resp)
      })
      .catch(err => {
        if (err?.response?.data?.ErrorCode === 404) {
          resolve(false)
          dispatch(checkPhone(false))
        } else {
          reject(err)
        }
      })
  })
export const userData = data => ({ type: SET_TOKEN, data })

export const logout = () => ({
  type: LOGOUT,
  initialState,
})
export const createUser = data => dispatch =>
  new Promise((resolve, reject) => {
    Axios.post(`${process.env.GATSBY_API_URL}/account/create`, data)
      .then(resp => {
        dispatch(userData(resp.data))
        resolve(resolve)
      })
      .catch(err => {
        reject(err)
      })
  })
export const login = data => dispatch =>
  new Promise((resolve, reject) => {
    Axios.post(`${process.env.GATSBY_API_URL}/account/login`, data)
      .then(({ data }) => {
        document.cookie = `dtoken=${data.token}; path=/; domain=.universal.org ; SameSite=None; Secure`
        document.cookie = `auth=true; path=/; SameSite=Strict; Secure`
        dispatch(userData(data))
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
export const loginByToken = token => dispatch =>
  new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/account`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        dispatch(userData({ ...resp.data, token, step: 0 }))
        document.cookie = `auth=true; path=/; SameSite=Strict; Secure`
        resolve(resp)
      })
      .catch(err => {
        reject(err)
        document.cookie = `auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict; Secure`
        document.cookie = `dtoken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.universal.org; SameSite=None; Secure`
        dispatch(logout())
      })
  })
export const updateCoordinates = (latitude, longitude) => ({
  type: UPDATE_COORDINATES,
  coords: { latitude, longitude },
})
export const setCreditCard = creditCard => ({
  type: SET_CREDIT_CARD,
  creditCard,
})
export const getCreditCard = token => (dispatch, store) =>
  new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/account/credit-card`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp.data)
        dispatch(setCreditCard(resp.data))
      })
      .catch(err => {
        dispatch(setCreditCard({}))
        reject(err)
      })
  })
export const setAddress = address => ({ type: SET_ADDRESS, address })
export const getAddress = token => dispatch =>
  new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/account/address`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp.data)
        dispatch(setAddress(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })

export const setUserData = userData => ({ type: SET_USER_DATA, userData })
export const getUserData = () => (dispatch, store) => {
  const { token } = store().app.user
  return new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/account`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(resp => {
        resolve(resp)
        dispatch(setUserData(resp.data))
      })
      .catch(err => {
        document.cookie = `auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict; Secure`
        document.cookie = `dtoken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.universal.org; SameSite=None; Secure`
        dispatch(logout())
        reject(err)
      })
  })
}

// --> Churchs
export const setChurchList = churchList => ({
  type: GET_CHURCH_LIST,
  churchList,
})
export const getChurchList = (
  size = 15,
  page = 1,
  query = null,
  latitude = null,
  longitude = null
) => (dispatch, store) => {
  const { coords } = store().app
  if (!latitude && !longitude) {
    if (coords.latitude && coords.longitude) {
      latitude = coords.latitude
      longitude = coords.longitude
    }
  }

  return new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/institutions`, {
      params: {
        size,
        page,
        query,
        latitude,
        longitude,
      },
    })
      .then(resp => {
        resolve(resp)
        dispatch(setChurchList(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })
}

// --> Campaigns
export const setInstitutionCampaigns = campaigns => ({
  type: SET_CHURCH_CAMPAIGN,
  campaigns,
})
export const getInstitutionCampaigns = (
  id = 5757,
  page = 1,
  size = 9999,
  searchParams = null,
  latitude = null,
  longitude = null
) => (dispatch, store) => {
  // Templo de salomão => 5757
  return new Promise((resolve, reject) => {
    const { coords } = store().app
    if (coords.latitude && coords.longitude) {
      latitude = coords.latitude
      longitude = coords.longitude
    }
    Axios.get(`${process.env.GATSBY_API_URL}/institutions/${id}/campaigns`, {
      params: {
        size,
        page,
        searchParams,
        latitude,
        longitude,
      },
    })
      .then(resp => {
        resolve(resp)
        dispatch(setChurchList(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getInstitutionCampaignsList = (
  id = 5757,
  page = 1,
  size = 9999,
  searchParams = null,
  latitude = null,
  longitude = null
) => (dispatch, store) => {
  // Templo de salomão => 5757
  return new Promise((resolve, reject) => {
    const { coords } = store().app
    if (coords.latitude && coords.longitude) {
      latitude = coords.latitude
      longitude = coords.longitude
    }
    Axios.get(`${process.env.GATSBY_API_URL}/campaigns/social`, {
      params: {
        size,
        page,
        searchParams,
        latitude,
        longitude,
      },
    })
      .then(resp => {
        resolve(resp)
        dispatch(setChurchList(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const setMainCampaign = mainCampaign => ({
  type: SET_MAIN_CAMPAIGN,
  mainCampaign,
})
export const getMainCampaign = () => dispatch =>
  new Promise((resolve, reject) => {
    Axios.get(`${process.env.GATSBY_API_URL}/campaigns/main-campaign`)
      .then(resp => {
        resolve(resp)
        dispatch(setMainCampaign(resp.data))
      })
      .catch(err => {
        reject(err)
      })
  })

export const setDonationComplete = donationCompleted => ({
  type: SET_DONATION_STATUS,
  donationCompleted,
})

export const setFormOfPayment = formOfPayment => ({
  type: SET_FORM_OF_PAYMENT,
  formOfPayment,
})

// ============ Reducer ============
export default (state = initialState, action) => {
  switch (action.type) {
    // --> Donation
    case SET_STEP:
      return { ...state, donationStep: action.donationStep }

    case SET_DESTINATION:
      return { ...state, donationDestination: action.donationDestination }

    case SET_CAMPAIGN:
      return { ...state, campaignDestination: action.campaignDestination }

    case GET_ALL_DONATIONS:
      return { ...state, user: { ...state.user, donations: action.donations } }
    case GET_RECURRENT_DONATIONS:
      return {
        ...state,
        user: { ...state.user, recurrentDonations: action.donations },
      }

    case SET_PIX_DATA:
      return {
        ...state,
        pixData: action.pixData,
      }

    case SET_DONATION_STATUS:
      return {
        ...state,
        donationCompleted: action.donationCompleted,
      }
    case SET_FORM_OF_PAYMENT:
      return {
        ...state,
        paymentType: action.formOfPayment,
      }

    // --> Auth
    case CHECK_PHONE:
      return { ...state, phoneRecognized: action.phoneRecognized }
    case SET_TOKEN:
      return { ...state, user: action.data }
    case UPDATE_COORDINATES:
      return { ...state, coords: action.coords }
    case SET_CREDIT_CARD:
      return { ...state, creditCard: action.creditCard }
    case SET_ADDRESS:
      return { ...state, address: action.address }
    case LOGOUT:
      return { ...initialState }
    case SET_CONFIRMATION:
      return { ...state, confirmationData: action.confirmationData }
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.userData.name,
          data: action.userData,
        },
      }

    // --> Churchs
    case GET_CHURCH_LIST:
      return { ...state, churchList: action.churchList }

    // --> Campaigns
    case SET_CHURCH_CAMPAIGN:
      return { ...state, defaultCampaigns: action.campaigns }
    case SET_MAIN_CAMPAIGN:
      return { ...state, mainCampaign: action.mainCampaign }

    default:
      return state
  }
}
